import React from 'react';
import { useTranslation } from 'react-i18next';
import Spacer from '../components/Spacer';

function FAQ() {
  const { t } = useTranslation();

  return (
    <>
      <Spacer />
      <div className="container" style={{ minHeight: 1000 }}>
        <h1 className="display-4 text-center text-primary">{t('faq.title')}</h1>
        <p className="text-primary" />
      </div>
    </>
  );
}

export default FAQ;
