export const NAVBAR_SCHEMA = {
  home: {
    text: 'CV Therapy',
    href: '/home',
  },
  items: [
    {
      dropdown: false,
      isActive: false,
      text: 'aboutMe',
      icon: 'user',
      href: '/about-me',
    },
    {
      dropdown: false,
      isActive: false,
      text: 'services',
      icon: 'clipboard',
      href: '/services',
    },
    {
      dropdown: false,
      isActive: false,
      text: 'contactMe',
      icon: 'envelope',
      href: '/contact-me',
    },
    // {
    //   dropdown: false,
    //   isActive: false,
    //   text: 'resources',
    //   icon: 'list',
    //   href: '/resources',
    // },
    // {
    //   dropdown: false,
    //   isActive: false,
    //   text: 'faq',
    //   icon: 'question',
    //   href: '/faq',
    // },
  ],
  language: {
    text: 'language',
    href: '/language',
  },
};

export default {
  NAVBAR_SCHEMA,
};
