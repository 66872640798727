import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useForm as formSpreeUseForm } from '@formspree/react';
import { useTranslation } from 'react-i18next';
import Spacer from '../components/Spacer';
import TextInput from '../components/utils/inputs/text-input';
import validation from '../components/utils/validation';

function Contact() {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit: formSubmitHandler,
    formState: { errors },
  } = useForm();
  const [successDev, setSuccessDev] = useState(false);
  const [state, handleSubmit] = formSpreeUseForm(process.env.REACT_APP_FORM_SPREE || '123');
  const onSubmit = (data) => {
    if (process.env.NODE_ENV === 'development') {
      setSuccessDev(true);
      return;
    }
    handleSubmit(data);
  };
  return (
    <>
      <Spacer />
      <div className="container" style={{ minHeight: 1000 }}>
        <h1 className="display-4 text-center text-primary">{t('contactMe.title')}</h1>
        <div className="row">
          <div className="col-md-3">
            <p className="text-primary">{t('contactMe.final-message')}</p>
            <p className="text-primary mb-4">
              {t('contactMe.phone')}
              <span>
                {' '}
                <a className="text-primary" href="tel:7604595939">
                  {t('phoneNumber')}
                </a>
              </span>
            </p>
            <p className="text-primary mb-4">
              {t('contactMe.fax')}
              <span>
                {' '}
                <span className="text-primary">
                  {t('faxNumber')}
                </span>
              </span>
            </p>
            <p className="text-primary">
              <i>
                {t('contactMe.privacy-p1')}
                <b>
                  {' '}
                  {t('contactMe.privacy-p2')}
                  {' '}
                </b>
                {t('contactMe.privacy-p3')}
              </i>
            </p>
          </div>
          <div className="col-md-9">
            { (state.succeeded || successDev) ? (
              <div className="mt-4 p-4">
                <p className="text-center text-primary h2">
                  {t('contactMe.thankyou')}
                </p>
              </div>
            ) : (
              <form className="" onSubmit={formSubmitHandler(onSubmit)}>
                <div className="row">
                  <div className="col-md-6">
                    <TextInput
                      label={t('contactMe.firstName')}
                      name="firstName"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: t('contactMe.firstName-error'),
                        },
                      }}
                      error={errors.firstName}
                    />
                  </div>
                  <div className="col-md-6">
                    <TextInput
                      label={t('contactMe.lastName')}
                      name="lastName"
                      register={register}
                      validation={{
                        required: {
                          value: true,
                          message: t('contactMe.lastName-error'),
                        },
                      }}
                      error={errors.lastName}
                    />
                  </div>
                </div>
                <TextInput
                  label={t('contactMe.email')}
                  name="email"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: t('contactMe.email-error'),
                    },
                    pattern: {
                      value: validation.EMAIL_REGEX,
                      message: t('contactMe.email-valid-error'),
                    },
                  }}
                  error={errors.email}
                />
                <TextInput
                  label={t('contactMe.message')}
                  name="message"
                  register={register}
                  validation={{
                    required: {
                      value: true,
                      message: t('contactMe.message-error'),
                    },
                  }}
                  error={errors.message}
                  placeholder={t('contactMe.messageProp')}
                  useTextArea
                />
                <div className="">
                  <div className="form-group d-flex justify-content-end">
                    <button className="btn btn-primary text-white mt-2" type="submit">{t('contactMe.submit')}</button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
