/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import Routes from './Routes';

const TRACKING_ID = 'UA-208916140-1'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function LoadingScreen() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center h-50">
        <img
          className="z-depth-2"
          src="./assets/logo.svg"
          alt="100x300"
          height={100}
          width={300}
        />
      </div>
    </div>
  );
}

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 800);
  }, []);

  return (
    loading
      ? (<LoadingScreen />)
      : (
        <div className="fade-in">
          <Routes />
        </div>
      )
  );
}

export default App;
