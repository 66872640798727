/* eslint-disable no-alert */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Fade from 'react-reveal/Fade';

import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import Spacer from '../components/Spacer';
import Profile from '../components/Profile';
import AppointmentLinks from '../components/custom/AppointmentLinks';
import LogoLinks from '../components/custom/LogoLinks';

function Home() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container-xxl p-0">
        <Spacer height={70} />
        <p className="bg-danger p-2 m-0 text-white">
          {t('home.alert-p1')}
          {' '}
          <a className="text-white no-underline" href="tel:7604595939">{t('phoneNumber')}</a>
          {' '}
          {t('home.alert-p2')}
          {' '}
          <a className="text-white no-underline" target="_blank" rel="noreferrer noopener" href="https://cvtherapy.clientsecure.me">{t('home.alert-p3')}</a>
          {' '}
          {t('home.alert-p4')}
        </p>
        <Hero height="30vh" image="./assets/background-image.jpg" />
        <Fade>
          <div className="row p-0 m-0">
            <div className="col-md-3">
              <Profile
                image={t('aboutMe.profileImage')}
              />
            </div>
            <div className="col-md-9 d-flex align-items-center">
              <div className="p-4">
                <h1 className="text-primary">{t('home.welcome')}</h1>
                <p className="text-primary">{t('home.main')}</p>
              </div>
            </div>
          </div>
        </Fade>
        <AppointmentLinks />
        <hr />
        <LogoLinks />
      </div>
    </>
  );
}

export default Home;
