/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from 'react';

function LogoLinks() {
  return (
    <div className="d-flex flex-row flex-wrap justify-content-center m-4">
      <div className="p-1">
        <a href="https://www.therapyden.com/therapist/maria-chavez-brown" target="_blank" rel="noreferrer noopener">
          <img
            className="rounded-circle z-depth-2"
            alt="85x85"
            height={85}
            width={85}
            src="./assets/home-image-1.png"
            data-holder-rendered="true"
          />
        </a>
      </div>
      {/* <div className="p-1">
        <img
          className="rounded-circle z-depth-2"
          alt="85x85"
          height={85}
          width={85}
          src="./assets/home-image-2.jpeg"
          data-holder-rendered="true"
        />
      </div> */}
      <div className="p-1">
        <a href="https://www.psychologytoday.com/profile/890288" target="_blank" rel="noreferrer noopener">
          <img
            className="rounded-circle z-depth-2"
            src="./assets/psychtoday.png"
            alt="85x85"
            height={85}
            width={85}
          />
        </a>
      </div>
      <div className="p-1">
        <img
          className="rounded-circle z-depth-2"
          src="./assets/pride.png"
          alt="85x85"
          height={85}
          width={85}
        />
      </div>
    </div>
  );
}

export default LogoLinks;
