/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Switch, Route, useLocation,
} from 'react-router-dom';
import ReactGA from 'react-ga';

import MainNavbar from './components/custom/MainNavbar';
import MainFooter from './components/custom/MainFooter';

import Home from './pages/Home';
import AboutMe from './pages/AboutMe';
import FAQ from './pages/FAQ';
import Services from './pages/Services';
import Contact from './pages/Contact';
import Resources from './pages/Resources';

const TRACKING_ID = 'UA-208916140-1'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [pathname]);

  return null;
}

function Routes() {
  return (
    <Router>
      <ScrollToTop />
      <MainNavbar />
      <Switch>
        <Route path="/about-me">
          <AboutMe />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/faq">
          <FAQ />
        </Route>
        <Route path="/contact-me">
          <Contact />
        </Route>
        <Route path="/resources">
          <Resources />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <MainFooter />
    </Router>
  );
}

export default Routes;
