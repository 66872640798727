/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';

function AppointMentLinks() {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-center">
      <div className="text-center mb-2 p-3"><a href="https://cvtherapy.clientsecure.me" target="_blank" rel="noreferrer noopener" className="text-white btn btn-warning" data-spwidget-scope-id="e922cc82-3f03-48df-b225-131d937adbf6" data-spwidget-scope-uri="cvtherapy" data-spwidget-application-id="7c72cb9f9a9b913654bb89d6c7b4e71a77911b30192051da35384b4d0c6d505b" data-spwidget-scope-global data-spwidget-autobind>{t('appointment.request')}</a></div>
      <div className="text-center mb-2 p-3"><a href="https://cvtherapy.clientsecure.me/sign-in" target="_blank" rel="noreferrer noopener" className="text-white btn btn-warning">{t('appointment.client')}</a></div>
      <script src="https://widget-cdn.simplepractice.com/assets/integration-1.0.js" />
    </div>
  );
}

export default AppointMentLinks;
